// cfgtables

const mutations = {
	getCfgRefTables(state, objects) {
  		state.cfgreftables = objects;
	},
	getCfgLists(state, object) {
		state.cfglists = object;
	},
	getCfgForms(state, objects) {
		state.cfgforms = objects;
  	},

};

export default mutations;