// cfgtables

import http from '../../../store/api/http' 

const actions = {
    getCfgRefTables(context) {
		return new Promise((resolve, reject) => {
			http.get('/api/cfgreftables')
			.then(function (response) {
		    	context.commit('getCfgRefTables', response.data)
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },
    getCfgLists(context, param) {
		console.log(param)
    	let query = '?table=' + param.table
    	query += '&ref=' + param.ref
		console.log(query)
		return new Promise((resolve, reject) => {
			http.get('/api/cfglists' + query)
			.then(function (response) {
		    	context.commit('getCfgLists', response.data)
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },
    updateCfgLists(context, data) {
		return new Promise((resolve, reject) => {
			http.post('/api/cfglists', data)
			.then(function (response) {
				console.log(response)
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },
    getCfgForms(context) {
		return new Promise((resolve, reject) => {
			http.get('/api/cfgforms')
			.then(function (response) {
		    	context.commit('getCfgForms', response.data)
				resolve(response)
			})
			.catch(function (error) {
				console.log(error)
				reject(error)
			});
		})
    },

};

export default actions;