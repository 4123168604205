// cfgtables

const getters = {
	getCfgRefTables(state) {
		return state.cfgreftables;
	},
	getCfgLists(state) {
		return state.cfglists;
	},
	getCfgForms(state) {
		return state.cfgforms;
	},

};

export default getters;